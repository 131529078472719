import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RotatingLines } from 'react-loader-spinner';
import { useFormData } from '../../context/FormDataContext';
import './EndingPage.css';
import { useHistory } from 'react-router-dom';
import logo from '../../SVG/logo/Video-KYC-Verification.jpg';

const EndingPage = () => {
  const { formData, frontPicture, backPicture, firstSelfie, handSelfie } =
    useFormData();
  const history = useHistory();

  const [isLoading, setisLoading] = useState(false);
  const [dataSaved, setdataSaved] = useState(false);

  const URL =
    'https://verification-backend-production.up.railway.app/api/admin/dashboard/submitdocs';

  useEffect(() => {
    sendDataToServer();
  }, []);

  const sendDataToServer = async () => {
    setisLoading(true);
    try {
      const forumData = new FormData();
      forumData.append('firstName', formData.firstName);
      forumData.append('lastName', formData.lastName);
      forumData.append('dob', formData.dob);
      forumData.append('nationality', formData.nationality);
      forumData.append('address', formData.address);
      forumData.append('email', formData.email);
      forumData.append('mobileNumber', formData.mobileNumber);
      forumData.append('frontselfie', frontPicture);
      forumData.append('backselfie', backPicture);
      forumData.append('recordselfie', firstSelfie);
      forumData.append('handselfie', handSelfie);

      const response = await axios.post(URL, forumData);
      setdataSaved(true);
      console.log(response.data);
    } catch (error) {
      setisLoading(false);
      alert('Please try again later, thank you!');
      history.push('/');
    } finally {
      setisLoading(false);
    }
  };
  return (
    <div>
      <div>
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img src={logo} width={200} height={100} alt="kyc" />
        </span>
      </div>
      <div className="heading-end">
        {isLoading ? (
          <h1 className="save-heading">
            Saving your <span>Verification</span> data...
          </h1>
        ) : dataSaved ? (
          <h1 className="saved-heading">
            Thank you for <span>completing</span> the process
          </h1>
        ) : (
          <h1 className="save-heading">
            <span>Initializing Process...</span>
          </h1>
        )}
        {isLoading && <RotatingLines strokeColor="#789af2" />}
      </div>
    </div>
  );
};

export default EndingPage;
