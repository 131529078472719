import React, { useEffect, useState } from 'react';
import './css/RecordSelfie.css';
import Webcam from 'react-webcam';
import RecordRTC from 'recordrtc';

import useTranslateText from '../../hooks';

const TranslatedText = ({ textKey }) => {
  return <>{useTranslateText(textKey)}</>;
};

const RecordSelfie = ({ onSwitch, getfirstSelfie }) => {
  const [webcamRef, setWebcamRef] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [saveRecording, setsaveRecording] = useState(null);
  const [sendRecording, setsendRecording] = useState(null);
  const [switchCam, setswitchCam] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [countDown, setcountDown] = useState(10);

  //! FUNCTIONS FOR EVENTS
  const startRecording = async () => {
    const stream = webcamRef.stream;
    const options = {
      type: 'video',
      mimeType: 'video/webm;codecs=vp9',
      bitsPerSecond: 128000,
    };

    const mediaRecorder = new RecordRTC(stream, options);
    mediaRecorder.startRecording();
    setRecorder(mediaRecorder);
    setIsRecording(true);

    const timer = setInterval(
      () => [setcountDown((prevCountDown) => prevCountDown - 1)],
      1000,
    );

    setTimeout(() => {
      stopRecording();
      clearInterval(timer);
    }, 10000);
  };

  const stopRecording = () => {
    if (recorder) {
      recorder.stopRecording(() => {
        const blob = recorder.getBlob();
        const file = new File([blob], 'recorded_video.webm', {
          type: 'video/webm',
        });
        setsendRecording(file);
        const videoURL = URL.createObjectURL(blob);
        setsaveRecording(videoURL);
        setswitchCam(true);
        setcountDown(0);
        setIsRecording(false);
      });
    }
  };
  //* FUNC for Actions
  const retake = () => {
    setcountDown(10);
    setswitchCam(false);
  };
  const changeCamera = async () => {
    if (saveRecording) {
      getfirstSelfie(sendRecording);
    }
    onSwitch(true);
  };
  useEffect(() => {
    return () => {
      if (saveRecording) {
        URL.revokeObjectURL(saveRecording);
      }
    };
  }, [saveRecording]);
  return (
    <div>
      <header className="record-title">
        <h1>
          {useTranslateText('Record Your')}
          <span> {useTranslateText('Selfie')}</span>
        </h1>
      </header>
      {switchCam ? (
        <>
          <video
            className="video-final"
            controls
            autoPlay
            playsInline
            src={saveRecording}
            width={600}
            height={200}
          />
          <div className="submit-video-btn">
            <button onClick={retake} className="btn-recorder">
              <TranslatedText textKey={'Retake'} />
            </button>
            <button onClick={changeCamera} className="btn-recorder cam-extra">
              <TranslatedText textKey={'Submit, next'} />
            </button>
          </div>
        </>
      ) : (
        <div className="back-camera">
          <div className="tests">
            <p className="title-p">
              <TranslatedText textKey={'Record for 10s'} />
            </p>
            <div className="picture-back">
              <Webcam
                className="video-cam"
                audio={false}
                mirrored={true}
                ref={(webcam) => setWebcamRef(webcam)}
                width={600}
                height={200}
              />
            </div>
            <div className="recorder-view-btn">
              <button
                onClick={isRecording ? stopRecording : startRecording}
                className="btn-recorder"
              >
                {isRecording ? (
                  <TranslatedText textKey={'Stop Recording...'} />
                ) : (
                  <TranslatedText textKey={'Start Recording'} />
                )}
              </button>
              {isRecording && (
                <p className="countdown">
                  <TranslatedText textKey={'Recording time remaining'} />:{' '}
                  {countDown} <TranslatedText textKey={'seconds'} />
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecordSelfie;
