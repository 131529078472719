import React, { useState } from "react";
import Scan from "./Scan";
import ProfileData from "../Form/ProfileData";

const DesktopScanner = () => {
  const [clicked, setClicked] = useState(false);

  const handleContinueClick = () => {
    setClicked(true);
  };

  if (!clicked) {
    return <ProfileData clicked={handleContinueClick} />;
  } else {
    return <Scan />;
  }
};

export default DesktopScanner;
