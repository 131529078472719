import React from 'react';
import './LandingPage.css';
import { useHistory } from 'react-router-dom';
// import { FaAngleUp } from "react-icons";

import useTranslateText from '../hooks';

const TranslatedText = ({ textKey }) => {
  return <>{useTranslateText(textKey)}</>;
};

const LandingPage = () => {
  const history = useHistory();
  return (
    <div className="main">
      <div className="header-title">
        <h1 className="heading">
          <TranslatedText textKey={'Get Verified'} />
        </h1>
        <p className="header-para">
          <TranslatedText textKey={'Go through to verification system'} />
        </p>
        <button
          className="header-btn"
          onClick={() => history.push('/admin/dashboard/verify')}
        >
          <TranslatedText textKey={'Start'} />
        </button>
      </div>
    </div>
  );
};

export default LandingPage;
