import React from 'react';
import Modal from 'react-modal';
import './Modal.css';
import { BigServiceSVG, SecuritySVG } from '../../constant/SVG';
import useTranslateText from '../../hooks';

Modal.setAppElement('#root');

const TranslatedText = ({ textKey }) => {
  return <>{useTranslateText(textKey)}</>;
};

const LangModal = ({ isOpen, onRequestClose, checkClick }) => {
  const modalClassName = isOpen ? 'modal active' : 'modal';

  const handleClick = () => {
    checkClick(true);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Modal"
      className={modalClassName}
      overlayClassName="overlay-class"
    >
      <div className="modal-content">
        <button onClick={onRequestClose} className="close-button">
          {useTranslateText('Close')}
        </button>
        <h1 className="modal-title">Supported Documents</h1>
        <div className="modal-scroll">
          <div className="svg-main">
            {BigServiceSVG.map((icon, idx) => {
              return (
                <div className="svg-container" key={idx}>
                  <div
                    className="img"
                    dangerouslySetInnerHTML={{ __html: icon.svg }}
                  ></div>
                  <button className="desc-btn" onClick={handleClick}>
                    <TranslatedText textKey={icon.desc} />
                  </button>
                </div>
              );
            })}
            <div className="border-svg"></div>
            <div className="security-span">
              <span>
                {useTranslateText(
                  'All collected data is SSL-encrypted and securely transmitted. Your documents are used only for verification purposes',
                )}
              </span>
            </div>
            <div className="security-svg">
              {SecuritySVG.map((icon, idx) => {
                return (
                  <div className="svgs" key={idx}>
                    <div
                      className="security-svgs"
                      dangerouslySetInnerHTML={{ __html: icon.svg }}
                    ></div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LangModal;
