import React, { Fragment, useEffect, useState } from 'react';
import './verification.css';
import ScannerOption from '../../render/ScannerOption';
import { SVG } from '../../../constant/SVG';
import DesktopScanner from '../../ScannerDevice/DesktopScanner';
import { useHistory } from 'react-router-dom';
import MobileScanner from '../../ScannerDevice/MobileScanner';

import useTranslateText from '../../../hooks';

const TranslatedText = ({ textKey }) => {
  return <>{useTranslateText(textKey)}</>;
};

const DashboardCard = () => {
  const history = useHistory();
  const [continueClicked, setContinueClicked] = useState(false);

  // For Device Selector
  const [selectedDevice, setSelectedDevice] = useState(null);

  // Device Selector Function
  const handleDeviceSelector = (deviceType) => {
    setSelectedDevice(deviceType);
  };

  useEffect(() => {
    if (selectedDevice === 'Mobile Scanner') {
      history.push('/admin/mobile/verify');
    }
  }, [selectedDevice, history]);

  useEffect(() => {
    setContinueClicked(true);
  }, [continueClicked]);

  return (
    <Fragment>
      {continueClicked && isMobileDevice() ? (
        <DesktopScanner />
      ) : selectedDevice ? (
        <div>
          {selectedDevice === 'other device' ? (
            <DesktopScanner />
          ) : (
            <MobileScanner />
          )}
        </div>
      ) : (
        continueClicked && (
          <>
            <div className="document-tile w-full h-full">
              <h1 className="document-scan-type-heading">
                <TranslatedText textKey={'Scan Document'} />
              </h1>
            </div>

            <div className="render-img-btn">
              {SVG.map((icon, idx) => (
                <ScannerOption
                  key={idx}
                  name={icon.name}
                  img={icon.svg}
                  type={icon.type}
                  desc={icon.desc}
                  banner={icon.banner}
                  onSelectDevice={handleDeviceSelector}
                />
              ))}
            </div>
          </>
        )
      )}
      {/* End of form */}
    </Fragment>
  );
};

export default DashboardCard;

function isMobileDevice() {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Samsung|Huawei|Xiaomi|Sony|Google Pixel/i.test(
      navigator.userAgent,
    );

  const isSmallScreen = window.innerWidth <= 1024;

  return isMobile && isSmallScreen;
}

// <form onSubmit={handleSubmit} className="dashboard-card-container">
// {/* Input fields for Name and Email */}

// <div
//   className={`input-fields ${
//     !stepName || !stepEmail ? "visible" : "hidden"
//   }`}
// >
//   <div className="mb-4">
//     <label className="block text-gray-700 text-sm font-bold mb-2">
//       Your Name:
//     </label>
//     <input
//       type="text"
//       className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//       placeholder="Your Name"
//       value={name}
//       onChange={(e) => setName(e.target.value)}
//     />
//   </div>
//   <div className="mb-4">
//     <label className="block text-gray-700 text-sm font-bold mb-2">
//       Your Email:
//     </label>
//     <input
//       type="email"
//       className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//       placeholder="Your Email"
//       value={email}
//       onChange={(e) => setEmail(e.target.value)}
//     />
//   </div>
//   <div className="mb-4">
//     <label className="block text-gray-700 text-sm font-bold mb-2">
//       Phone No :
//     </label>
//     <input
//       type="text"
//       className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//       placeholder="Phone"
//       value={phone}
//       onChange={(e) => setPhone(e.target.value)}
//     />
//   </div>
//   <button
//     type="submit"
//     className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
//   >
//     Continue
//   </button>
// </div>
// {/* scan type */}
// </form>
