import React, { createContext, useContext, useState } from "react";

const FormDataContext = createContext();

export const FormDataProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    nationality: "",
    address: "",
    email: "",
    mobileNumber: "",
  });
  const [frontPicture, setFrontPicture] = useState(null);
  const [backPicture, setBackPicture] = useState(null);
  const [firstSelfie, setFirstSelfie] = useState(null);
  const [handSelfie, setHandSelfie] = useState(null);

  return (
    <FormDataContext.Provider
      value={{
        formData,
        setFormData,
        frontPicture,
        setFrontPicture,
        backPicture,
        setBackPicture,
        firstSelfie,
        setFirstSelfie,
        handSelfie,
        setHandSelfie,
      }}
    >
      {children}
    </FormDataContext.Provider>
  );
};

export const useFormData = () => {
  return useContext(FormDataContext);
};
