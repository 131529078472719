import React, { useState } from 'react';
import './Scanner.css';
import { ServiceSVG } from '../../constant/SVG';
import LangModal from '../modal/LangModal';
import Identification from '../verifications/Identification';

import useTranslateText from '../../hooks';

const TranslatedText = ({ textKey }) => {
  return <>{useTranslateText(textKey)}</>;
};

const Scan = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clicked, setClicked] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {clicked ? (
        <Identification />
      ) : (
        <div className="scan-contianer">
          <h1 className="main-title">
            <TranslatedText textKey={'Scan Document'} />
          </h1>
          <div className="svg-render">
            <div className="card">
              <div className="card-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="300"
                  height="180"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="scan-animation"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 5C2.44772 5 2 5.44771 2 6V18C2 18.5523 2.44772 19 3 19H21C21.5523 19 22 18.5523 22 18V6C22 5.44772 21.5523 5 21 5H3ZM0 6C0 4.34315 1.34314 3 3 3H21C22.6569 3 24 4.34315 24 6V18C24 19.6569 22.6569 21 21 21H3C1.34315 21 0 19.6569 0 18V6ZM6 10.5C6 9.67157 6.67157 9 7.5 9C8.32843 9 9 9.67157 9 10.5C9 11.3284 8.32843 12 7.5 12C6.67157 12 6 11.3284 6 10.5ZM10.1756 12.7565C10.69 12.1472 11 11.3598 11 10.5C11 8.567 9.433 7 7.5 7C5.567 7 4 8.567 4 10.5C4 11.3598 4.31002 12.1472 4.82438 12.7565C3.68235 13.4994 3 14.7069 3 16C3 16.5523 3.44772 17 4 17C4.55228 17 5 16.5523 5 16C5 15.1145 5.80048 14 7.5 14C9.19952 14 10 15.1145 10 16C10 16.5523 10.4477 17 11 17C11.5523 17 12 16.5523 12 16C12 14.7069 11.3177 13.4994 10.1756 12.7565ZM13 8C12.4477 8 12 8.44772 12 9C12 9.55228 12.4477 10 13 10H19C19.5523 10 20 9.55228 20 9C20 8.44772 19.5523 8 19 8H13ZM14 12C13.4477 12 13 12.4477 13 13C13 13.5523 13.4477 14 14 14H18C18.5523 14 19 13.5523 19 13C19 12.4477 18.5523 12 18 12H14Z"
                    fill="#000000"
                  />
                </svg>
              </div>
              <div className="card-border">
                <svg
                  className="GiDocumentAnimation-Corners"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  preserveAspectRatio="xMidYMid meet"
                  width="250"
                  height="188"
                  viewBox="0 0 303 188"
                >
                  <g transform=" translate(151.5, 94) translate(-152.5, -95)">
                    <path
                      id="gi-svg-doc-top-left-corner"
                      stroke="#47DAAEFF"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      strokeWidth="3"
                      strokeOpacity="1"
                      d=" M27 3 C27,3 11,3 11,3 C6.58,3 3,6.58 3,11 C3,11 3,27 3,27 "
                    ></path>
                    <path
                      id="gi-svg-doc-top-right-corner"
                      stroke="#47DAAEFF"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      strokeWidth="3"
                      strokeOpacity="1"
                      d=" M278 3 C278,3 294,3 294,3 C298.42,3 302,6.58 302,11 C302,11 302,27 302,27 "
                    ></path>
                    <path
                      id="gi-svg-doc-bottom-left-corner"
                      stroke="#47DAAEFF"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      strokeWidth="3"
                      strokeOpacity="1"
                      d=" M3 163 C3,163 3,179 3,179 C3,183.42 6.58,187 11,187 C11,187 27,187 27,187 "
                    ></path>
                    <path
                      id="gi-svg-doc-bottom-right-corner"
                      stroke="#47DAAEFF"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      strokeWidth="3"
                      strokeOpacity="1"
                      d=" M302 163 C302,163 302,179 302,179 C302,183.42 298.42,187 294,187 C294,187 278,187 278,187 "
                    ></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div className="three-elements" onClick={openModal}>
            <div className="service-img">
              {ServiceSVG.map((icon, idx) => (
                <div className="img" key={idx}>
                  <div className="svg-div">
                    <div dangerouslySetInnerHTML={{ __html: icon.svg }}></div>
                    <p className="span">
                      <TranslatedText textKey={icon.desc} />
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {isModalOpen && (
            <LangModal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              checkClick={setClicked}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Scan;
