import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import './css/BackCamera.css';
import UpArrow from '../../SVG/UpArrow';

import useTranslateText from '../../hooks';

const TranslatedText = ({ textKey }) => {
  return <>{useTranslateText(textKey)}</>;
};

const BackCamera = ({ selfie, getBackSelfie }) => {
  const [imgSRC, setimgSRC] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [sendFile, setsendFile] = useState(null);
  const [off, setOff] = useState(false);
  const [offbtn, setOffbtn] = useState(false);
  const [frontCamera, setfrontCamera] = useState(true);

  const webCamRef = useRef(null);

  //! FUNCTIONS FOR EVNETS

  const onCapture = async () => {
    setOff(true);
    const webcam = webCamRef.current;
    if (webcam) {
      const imageSRC = webcam.getScreenshot();
      if (imageSRC) {
        try {
          const response = await fetch(imageSRC);
          const blob = await response.blob();
          const file = new File([blob], 'backselfie.jpeg', {
            type: 'image/jpeg',
          });
          setimgSRC(file);
        } catch (error) {
          console.error('Error converting image to blob:', error);
        }
      }
    }
  };
  const handleInputChange = (e) => {
    setOffbtn(true);
    const file = e.target.files[0];

    if (file) {
      if (
        (file && file.type.includes('image/jpeg')) ||
        file.type.includes('image/jpg') ||
        file.type.includes('image/png')
      ) {
        setsendFile(file);
        const imageURL = URL.createObjectURL(file);
        setSelectedFile(imageURL);
      } else {
        alert('Please select a valid jpg or jpeg image.');
      }
    }
  };
  //* FUNC for Actions
  const retake = () => {
    setimgSRC(null);
    setOff(false);
  };
  const uploadAgain = () => {
    setOffbtn(false);
    setSelectedFile(null);
  };
  const onContinue = async () => {
    if (imgSRC || selectedFile) {
      if (imgSRC) {
        getBackSelfie(imgSRC);
      }
      if (selectedFile) {
        getBackSelfie(sendFile);
      }
    }
    // history.push("/admin/dashboard/submitdocs");
    selfie(true);
  };

  const toggleCamera = () => {
    setfrontCamera(!frontCamera);
  };

  return (
    <div>
      <header className="main-title">
        <h1>
          {useTranslateText('Capture')} <span> {useTranslateText('BACK')}</span>{' '}
          {useTranslateText('page of Documents')}
        </h1>
      </header>
      <div className="back-camera">
        {imgSRC ? (
          imgSRC && (
            <div>
              <img src={URL.createObjectURL(imgSRC)} alt="back img" />
            </div>
          )
        ) : (
          <div>
            {frontCamera ? (
              <div className="picture-front">
                <Webcam
                  audio={false}
                  ref={webCamRef}
                  width={600}
                  height={200}
                  videoConstraints={{ facingMode: 'user' }}
                />
              </div>
            ) : (
              <div className="picture-front">
                <Webcam
                  audio={false}
                  ref={webCamRef}
                  width={600}
                  height={200}
                  videoConstraints={{ facingMode: 'environment' }}
                />
              </div>
            )}
            <div className="btn-switchers">
              <button
                onClick={onCapture}
                disabled={offbtn}
                className="btn-front"
              >
                <TranslatedText textKey={'Capture'} />
              </button>
              {isMobileDevice() && (
                <button onClick={toggleCamera} className="btn-front toggle">
                  <UpArrow />
                </button>
              )}
            </div>
          </div>
        )}
        {imgSRC && (
          <div className="view-btns-cam">
            <button onClick={retake} className="btn-back">
              <TranslatedText textKey={'Retake'} />
            </button>
            <button onClick={onContinue} className="btn-back extra">
              <TranslatedText textKey={'Next: record selfie'} />
            </button>
          </div>
        )}
      </div>
      <div className="upload-title">
        <h1>
          {useTranslateText('OR upload')}
          <span> {useTranslateText('BACK')}</span>{' '}
          {useTranslateText('page of Document')}
        </h1>
      </div>
      <div className="upload-front">
        {selectedFile ? (
          <div className="view-btns-file">
            <button onClick={uploadAgain} className="back-retake">
              <TranslatedText textKey={'Upload Again'} />
            </button>
            <button onClick={onContinue} className="back-retake extra">
              <TranslatedText textKey={'Next: record selfie'} />
            </button>
          </div>
        ) : (
          <>
            <input
              type="file"
              id="file-input"
              className="input-link"
              accept=".png, .jpg, .jpeg"
              disabled={off}
              onChange={handleInputChange}
            />
            <label htmlFor="file-input" className="custom-upload-button">
              <TranslatedText textKey={'Choose File'} />
            </label>
          </>
        )}
      </div>
      {selectedFile && (
        <img
          className="upload-img"
          src={selectedFile}
          width={600}
          height={200}
          alt="uploaded"
        />
      )}
    </div>
  );
};

export default BackCamera;

function isMobileDevice() {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

  const isSmallScreen = window.innerWidth <= 1024;

  return isMobile && isSmallScreen;
}
