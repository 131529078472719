import React from "react";

import { DashboardAdmin } from "./admin";

import { BrowserRouter as Router, Switch } from "react-router-dom";
import LandingPage from "../LandingPage/LandingPage";
import EndingPage from "./EndingPage/EndingPage";

/* Routing All page will be here */
const Routes = () => {
  return (
    <Router>
      <Switch>
        <LandingPage exact={true} path="/" component={LandingPage} />
        <DashboardAdmin
          exact={true}
          path="/admin/dashboard/verify"
          component={DashboardAdmin}
        />
        <EndingPage
          exact={true}
          path="/admin/dashboard/submitdocs"
          component={EndingPage}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
