import React from 'react';
import useTranslateText from '../../hooks';

const TranslatedText = ({ textKey }) => {
  return <>{useTranslateText(textKey)}</>;
};

const ScannerOption = ({ img, type, desc, banner, onSelectDevice, name }) => {
  return (
    <>
      <div
        className="scanner-opt-selector"
        onClick={() => onSelectDevice(type)}
      >
        <button className="selector-btn">
          <div
            className="svgs-link"
            dangerouslySetInnerHTML={{ __html: img }}
          />
          <div className="svg-desc">
            <p>
              <TranslatedText textKey={name} />
            </p>
            <span>
              <TranslatedText textKey={desc} />
            </span>
          </div>
        </button>
      </div>
    </>
  );
};

export default ScannerOption;
