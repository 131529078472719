import React, { useState } from 'react';
import { useFormData } from '../../context/FormDataContext';
import useTranslateText from '../../hooks';
import './ProfileData.css';

const TranslatedText = ({ textKey }) => {
  return <>{useTranslateText(textKey)}</>;
};

const ProfileData = ({ clicked }) => {
  const { formData, setFormData } = useFormData();
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.firstName.trim()) {
      newErrors.firstName = 'First Name is required';
      isValid = false;
    }

    if (!formData.lastName.trim()) {
      newErrors.lastName = 'Last Name is required';
      isValid = false;
    }

    if (!formData.dob.trim()) {
      newErrors.dob = 'Date of Birth is required';
      isValid = false;
    }

    if (!formData.nationality.trim()) {
      newErrors.nationality = 'Nationality is required';
      isValid = false;
    }

    if (!formData.address.trim()) {
      newErrors.address = 'Address is required';
      isValid = false;
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!emailPattern.test(formData.email)) {
      newErrors.email = 'Invalid email format';
      isValid = false;
    }

    const mobilePattern = /^\d+$/;
    if (!formData.mobileNumber.trim()) {
      newErrors.mobileNumber = 'Mobile Number is required';
      isValid = false;
    } else if (!mobilePattern.test(formData.mobileNumber)) {
      newErrors.mobileNumber = 'Invalid mobile number format';
      isValid = false;
    }

    setErrors(newErrors);

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      clicked();
    } else {
      alert('Form validation failed. Please check the fields');
    }
  };

  return (
    <div className="formMain">
      <p>{useTranslateText('Profile Data')}</p>
      <form className="formInner" onSubmit={handleSubmit}>
        <div className="formRow">
          <div>
            <label htmlFor="firstname">{useTranslateText('First Name')}:</label>
            <input
              type="text"
              id="firstname"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
            {errors.firstName && (
              <p className="text-red-500 text-sm mt-1">
                <TranslatedText textKey={errors.firstName} />
              </p>
            )}
          </div>
          <div>
            <label htmlFor="lastname">{useTranslateText('Last Name')}:</label>
            <input
              type="text"
              id="lastname"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
            {errors.lastName && (
              <p className="text-red-500 text-sm mt-1">
                {' '}
                <TranslatedText textKey={errors.lastName} />
              </p>
            )}
          </div>
        </div>
        <div className="formRow">
          <div>
            <label htmlFor="nationality">
              {useTranslateText('Nationality')}:
            </label>
            <input
              type="text"
              id="nationality"
              name="nationality"
              value={formData.nationality}
              onChange={handleChange}
            />
            {errors.nationality && (
              <p className="text-red-500 text-sm mt-1">
                <TranslatedText textKey={errors.nationality} />
              </p>
            )}
          </div>
          <div>
            <label htmlFor="date">{useTranslateText('Birth Date')}:</label>
            <input
              type="date"
              id="date"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
            />
            {errors.dob && (
              <p className="text-red-500 text-sm mt-1">
                <TranslatedText textKey={errors.dob} />
              </p>
            )}
          </div>
        </div>
        <div className="formRow">
          <div>
            <label htmlFor="address">{useTranslateText('Address')}:</label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
            {errors.address && (
              <p className="text-red-500 text-sm mt-1">
                <TranslatedText textKey={errors.address} />
              </p>
            )}
          </div>
        </div>
        <div className="formRow">
          <div>
            <label htmlFor="email">{useTranslateText('Email')}:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">
                <TranslatedText textKey={errors.email} />
              </p>
            )}
          </div>
          <div>
            <label htmlFor="phone">{useTranslateText('Phone Number')}:</label>
            <input
              type="tel"
              id="phone"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
            />
            {errors.mobileNumber && (
              <p className="text-red-500 text-sm mt-1">
                {' '}
                <TranslatedText textKey={errors.mobileNumber} />
              </p>
            )}
          </div>
        </div>
        <button type="submit">{useTranslateText('Continue')}</button>
      </form>
    </div>
  );
};

export default ProfileData;
