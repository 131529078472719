import React, { Fragment } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import useTranslateText from '../../../hooks';

const TranslatedText = ({ textKey }) => {
  return <>{useTranslateText(textKey)}</>;
};

const AdminSidebar = (props) => {
  const location = useLocation();
  const history = useHistory();

  return (
    <Fragment>
      <div
        style={{ boxShadow: '1px 1px 8px 0.2px #aaaaaa' }}
        id="sidebar"
        className="hidden md:block sticky top-0 left-0 h-screen md:w-3/12 lg:w-2/12 sidebarShadow bg-white text-gray-600"
      >
        <hr className="border-b border-gray-200" />
        <div
          onClick={(e) => history.push('/admin/dashboard/verify')}
          className={`${
            location.pathname === '/'
              ? 'border-r-4 border-gray-800 bg-gray-100'
              : ''
          } hover:bg-gray-200 cursor-pointer flex flex-col items-center justify-center py-6`}
        >
          <span>
            <svg
              className="w-8 h-8 text-gray-600 hover:text-gray-800"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
              />
            </svg>
          </span>
          <span className="hover:text-gray-800">
            <TranslatedText textKey={'Get Verified'} />
          </span>
        </div>
        <hr className="border-b border-gray-200" />

        <hr className="border-b border-gray-200" />
      </div>
    </Fragment>
  );
};

export default AdminSidebar;
