import React, { Fragment } from 'react';
import Routes from './components';
import { DataCollectorProvider } from './context/DataCollectorContext';
import { FormDataProvider } from './context/FormDataContext';

function App() {
  return (
    <Fragment>
      <FormDataProvider>
        <DataCollectorProvider>
          <Routes />
        </DataCollectorProvider>
      </FormDataProvider>
    </Fragment>
  );
}

export default App;
