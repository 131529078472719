import React from "react";
import QRCode from "react-qr-code";

const MobileScanner = () => {
  return (
    <div>
      <h1 className="main-title">
        Scan <span>QR Code</span> to continue
      </h1>
      <div
        style={{
          height: "auto",
          margin: "5rem auto",
          maxWidth: 300,
          width: "100%",
        }}
      >
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value="https://get-verified.app/admin/dashboard/verify"
          viewBox={`0 0 256 256`}
        />
      </div>
    </div>
  );
};

export default MobileScanner;
