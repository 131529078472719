import React, { useState } from "react";
import "@fortawesome/fontawesome-free/css/all.css";
import "./css/verification.css";
import FrontCamera from "./FrontCamera";
import BackCamera from "./BackCamera";
import RecordSelfie from "./RecordSelfie";
import HandSelfie from "./HandSelfie";
import { useFormData } from "../../context/FormDataContext";

import useTranslateText from '../../hooks';

const TranslatedText = ({ textKey }) => {
  return <>{useTranslateText(textKey)}</>;
};

const Identification = () => {
  //* context form
  const { setFrontPicture, setBackPicture, setFirstSelfie, setHandSelfie } =
    useFormData();
  const [next, setNext] = useState(false);
  const [recordSelfie, setRecordSelfie] = useState(false);
  const [switchSelfie, setswitchSelfie] = useState(false);

  const handleNext = () => {
    setNext(true);
  };
  const onRecording = () => {
    setRecordSelfie(true);
  };
  const handleSwitch = () => {
    setswitchSelfie(true);
  };
  //* func for props
  const getFrontSelfie = (img) => {
    console.log("front selfie:", img);
    setFrontPicture(img);
  };
  const getBackSelfie = (img) => {
    console.log("back selfie:", img);
    setBackPicture(img);
  };
  const getfirstSelfie = (video) => {
    console.log("front face recording", video);
    setFirstSelfie(video);
  };
  const gethandSelfie = (video) => {
    console.log("hand selfie recording", video);
    setHandSelfie(video);
  };
  return (
    <div>
      {!next ? (
        <div className="capture-front">
          <FrontCamera clickNext={handleNext} getFrontPic={getFrontSelfie} />
        </div>
      ) : recordSelfie ? (
        <div className="record-selfie">
          {switchSelfie ? (
            <HandSelfie gethandSelfie={gethandSelfie} />
          ) : (
            <RecordSelfie
              onSwitch={handleSwitch}
              getfirstSelfie={getfirstSelfie}
            />
          )}
        </div>
      ) : (
        <div className="capture-back">
          <BackCamera getBackSelfie={getBackSelfie} selfie={onRecording} />
        </div>
      )}
    </div>
  );
};

export default Identification;
