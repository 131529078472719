import React, { useState } from 'react';
import { useTranslationContext } from '../../../context/TranslationContextProvider';
import france from '../../../assets/countries/france.svg';
import germany from '../../../assets/countries/germany.svg';
import italy from '../../../assets/countries/italy.svg';
import spain from '../../../assets/countries/spain.png';
import united_states from '../../../assets/countries/united-states.svg';

const LanguageSelector = () => {
  const { translatedLanguage, setTranslatedLangauge } = useTranslationContext();
  const [isOpen, setIsOpen] = useState(false);

  const handleLanguageChange = (code) => {
    setTranslatedLangauge(code);
    setIsOpen(false);
  };

  const languages = [
    {
      code: 'de',
      name: 'German',
      flag: germany,
    },
    {
      code: 'it',
      name: 'Italian',
      flag: italy,
    },
    {
      code: 'es',
      name: 'Spanish',
      flag: spain,
    },
    {
      code: 'en',
      name: 'English',
      flag: united_states,
    },
    {
      code: 'fr',
      name: 'French',
      flag: france,
    },
  ];

  return (
    <div className="languageSelector lg:mx-4 lg:my-4 mx-4 my-1">
      <div
        className="rounded-lg p-2 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          src={languages.find((lang) => lang.code === translatedLanguage)?.flag}
          alt={`${
            languages.find((lang) => lang.code === translatedLanguage)?.name
          } Flag`}
          className="h-8 w-8 my-0 md:h-10 md:w-10 md:my-0 sm:my-1"
        />
      </div>
      {isOpen && (
        <div className="absolute bg-white border rounded-lg mt-2 shadow-lg">
          {languages.map((lang) => (
            <div
              key={lang.code}
              className="flex items-center p-2 cursor-pointer"
              onClick={() => handleLanguageChange(lang.code)}
            >
              <img
                src={lang.flag}
                alt={`${lang.name} Flag`}
                className="w-7 h-7 md:h-10 md:w-10"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
