import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../../SVG/logo/Video-KYC-Verification.jpg';
import './partials.css';
import useTranslateText from '../../../hooks';
import LanguageSelector from './LanguageSelector';

const TranslatedText = ({ textKey }) => {
  return <>{useTranslateText(textKey)}</>;
};

const AdminNavber = (props) => {
  const history = useHistory();

  return (
    <Fragment>
      <nav className="sticky z-10 flex items-center shadow-md justify-between px-4 py-4 md:px-8 top-0 w-full bg-white">
        {/*  Large Screen Show  */}
        <div className="hidden lg:flex lg:items-center lg:space-x-4">
          <span>
            <img
              src={logo}
              width={120}
              alt="Your Icon"
              className="cursor-pointer"
              onClick={(e) => history.push('/')}
            />
          </span>
        </div>
        {/*  Large Screen Show  */}
        <div className="hidden lg:block">
          <span
            style={{ letterSpacing: '0.70rem' }}
            className="flex items-left font-bold uppercase text-gray-800 text-3xl cursor-pointer px-2 text-center"
          >
            {useTranslateText('Get Verified')}
          </span>
        </div>

        {/* medium screen */}
        <div className="lg:hidden">
          <span>
            <svg
              className="w-8 h-8 cursor-pointer text-gray-600 svg-small"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </span>
        </div>
        {/*  medium Screen Show  */}
        <div className="lg:hidden ">
          <span
            onClick={() => history.push('/')}
            className="flex items-left font-bold uppercase text-grey-800 text-2xl cursor-pointer px-2 text-center custom"
          >
            <TranslatedText textKey={'Get Verified'} />
          </span>
        </div>

        {/* Both Screen show */}
        <div className="flex items-center"></div>
        {/* Mobile Navber */}
        {/* End Mobile Navber */}
      </nav>

      <div className="fixed z-50 right-0 mt-1 lg:mt-3">
        <LanguageSelector />
      </div>
    </Fragment>
  );
};

export default AdminNavber;
