// UpArrow.js
import React from "react";

const UpArrow = () => (
  <svg
    fill="#000000"
    height="20px"
    width="20px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 330 330"
    // xml:space="preserve"
    // color=""
  >
    <path
      id="XMLID_22_"
      d="M100.606,229.393c-5.857-5.857-15.355-5.857-21.213,0c-5.858,5.857-5.858,15.355,0,21.213l75,75
	C157.322,328.535,161.161,330,165,330c3.839,0,7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213
	c-5.857-5.857-15.355-5.857-21.213,0L180,278.787V51.212l49.394,49.394C232.322,103.535,236.161,105,240,105
	c3.839,0,7.678-1.465,10.606-4.394c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.858-15.355-5.858-21.213,0l-75,75
	c-5.858,5.857-5.858,15.355,0,21.213c5.857,5.857,15.355,5.857,21.213,0L150,51.212v227.574L100.606,229.393z"
    />
  </svg>
);

export default UpArrow;
