import React, { createContext, useContext, useState } from "react";

const DataCollectorContext = createContext();

export const DataCollectorProvider = ({ children }) => {
  const [collectedData, setCollectedData] = useState({});

  const updateData = (newData) => {
    setCollectedData(newData);
  };

  return (
    <DataCollectorContext.Provider value={{ collectedData, updateData }}>
      {children}
    </DataCollectorContext.Provider>
  );
};

export const useDataCollector = () => {
  return useContext(DataCollectorContext);
};
