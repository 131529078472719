import { useState, useEffect } from 'react';
import { useTranslationContext } from '../context/TranslationContextProvider';

const useTranslateText = (text) => {
  const { translateText } = useTranslationContext();
  const [translatedText, setTranslatedText] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (text) {
      setIsLoading(true);
      translateText(text)
        .then((translatedText) => {
          setTranslatedText(translatedText);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error translating text:', error);
          setIsLoading(false);
        });
    }
  }, [text, translateText]);

  return isLoading ? <>Loading...</> : translatedText;
};

export default useTranslateText;
