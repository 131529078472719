import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import './css/FrontCamera.css';
import UpArrow from '../../SVG/UpArrow';

import useTranslateText from '../../hooks';

const TranslatedText = ({ textKey }) => {
  return <>{useTranslateText(textKey)}</>;
};

const FrontCamera = ({ clickNext, getFrontPic }) => {
  const webCamRef = useRef(null);
  const [imgSRC, setimgSRC] = useState(null);
  const [off, setOff] = useState(false);
  const [offbtn, setOffbtn] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [sendFile, setsendFile] = useState(null);
  const [cameraError, setCameraError] = useState(false);
  const [frontCamera, setfrontCamera] = useState(true);

  useEffect(() => {
    checkCameraPermission();
  }, []);

  const checkCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    } catch (error) {
      setCameraError(true);
    }
  };

  //! FUNCTIONS FOR EVENTS
  const onCapture = async () => {
    setOff(true);
    const webcam = webCamRef.current;
    if (webcam) {
      const imageSRC = webcam.getScreenshot();
      if (imageSRC) {
        try {
          const response = await fetch(imageSRC);
          const blob = await response.blob();
          const file = new File([blob], 'frontselfie.jpeg', {
            type: 'image/jpeg',
          });
          setimgSRC(file);
        } catch (error) {
          console.error('Error converting image to blob:', error);
        }
      }
    }
  };

  const handleInputChange = (e) => {
    setOffbtn(true);
    const file = e.target.files[0];

    if (file) {
      if (
        (file && file.type.includes('image/jpeg')) ||
        file.type.includes('image/jpg') ||
        file.type.includes('image/png')
      ) {
        setsendFile(file);
        const filetoURL = URL.createObjectURL(file);
        setSelectedFile(filetoURL);
      } else {
        alert('Please select a valid jpg or jpeg image.');
      }
    }
  };

  //* FUNC for Actions
  const retake = () => {
    setimgSRC(null);
    setOff(false);
  };

  const uploadAgain = () => {
    setOffbtn(false);
    setSelectedFile(null);
  };

  const onContinue = async () => {
    if (imgSRC || selectedFile) {
      if (imgSRC) {
        getFrontPic(imgSRC);
      }
      if (selectedFile) {
        getFrontPic(sendFile);
      }
    }
    clickNext(true);
  };
  const toggleCamera = () => {
    setfrontCamera(!frontCamera);
  };
  return (
    <div>
      {cameraError && (
        <p>
          <TranslatedText
            textKey={
              'Error: Camera access denied. Please allow camera access and refresh the page.'
            }
          />
        </p>
      )}
      {cameraError ? null : (
        <>
          <header className="main-title">
            <h1>
              <TranslatedText textKey={'Capture'} />
              <span>
                {' '}
                <TranslatedText textKey={'FRONT'} />
              </span>{' '}
              <TranslatedText textKey={'page of Document'} />
            </h1>
          </header>
          <div className="front-camera">
            {imgSRC ? (
              imgSRC && (
                <div>
                  <img src={URL.createObjectURL(imgSRC)} alt="front img" />
                </div>
              )
            ) : (
              <div>
                {frontCamera ? (
                  <div className="picture-front">
                    <Webcam
                      audio={false}
                      ref={webCamRef}
                      width={600}
                      height={200}
                      videoConstraints={{ facingMode: 'user' }}
                    />
                  </div>
                ) : (
                  <div className="picture-front">
                    <Webcam
                      audio={false}
                      ref={webCamRef}
                      width={600}
                      height={200}
                      videoConstraints={{ facingMode: 'environment' }}
                    />
                  </div>
                )}
                <div className="btn-switchers">
                  <button
                    onClick={onCapture}
                    disabled={offbtn}
                    className="btn-front"
                  >
                    <TranslatedText textKey={'Capture'} />
                  </button>
                  {isMobileDevice() && (
                    <button onClick={toggleCamera} className="btn-front toggle">
                      <UpArrow />
                    </button>
                  )}
                </div>
              </div>
            )}
            {imgSRC && (
              <div className="view-btns-cam">
                <button onClick={retake} className="btn-front">
                  <TranslatedText textKey={'Retake'} />
                </button>
                <button onClick={onContinue} className="btn-front front-extra">
                  <TranslatedText textKey={'Continue next'} />
                </button>
              </div>
            )}
          </div>
          <div className="upload-title">
            <h1>
              <TranslatedText textKey={'OR upload'} />
              <span>
                {' '}
                <TranslatedText textKey={'FRONT'} />
              </span>{' '}
              <TranslatedText textKey={'page of Document'} />
            </h1>
          </div>
          <div className="upload-front">
            {selectedFile ? (
              <div className="view-btns-file">
                <button onClick={uploadAgain} className="front-retake">
                  <TranslatedText textKey={'Upload Again'} />
                </button>
                <button
                  onClick={onContinue}
                  className="front-retake front-extra"
                >
                  <TranslatedText textKey={'Continue next'} />
                </button>
              </div>
            ) : (
              <>
                <input
                  type="file"
                  id="file-input"
                  className="input-link"
                  accept=".png, .jpg, .jpeg"
                  disabled={off}
                  onChange={handleInputChange}
                />
                <label htmlFor="file-input" className="custom-upload-button">
                  <TranslatedText textKey={'Choose File'} />
                </label>
              </>
            )}
          </div>
          {selectedFile && (
            <img
              className="upload-img"
              src={selectedFile}
              width={600}
              height={200}
              alt="uploaded"
            />
          )}
        </>
      )}
    </div>
  );
};

export default FrontCamera;

function isMobileDevice() {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

  const isSmallScreen = window.innerWidth <= 1024;

  return isMobile && isSmallScreen;
}
