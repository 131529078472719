import React, { Fragment } from "react";
import AdminLayout from "../layout";
// import DashboardCard from "./DashboardCard";
// import Dashboard from "./Dashboard";
import DashboardCard from "./DashboardCard";


const DashboardComponent = () => {
  return (
    <Fragment>
      <DashboardCard />


    </Fragment>
  );
};

const DashboardAdmin = (props) => {

  return (
    <Fragment>

        <AdminLayout children={<DashboardComponent />} />

    </Fragment>
  );
};

export default DashboardAdmin;
