import React, { useState, useContext, createContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const TranslationContext = createContext();

export const TranslationContextProvider = ({ children }) => {
  const [translatedLanguage, setTranslatedLangauge] = useState('en');

  let key = process.env.REACT_APP_TRANSLATE_API_KEY;
  let endpoint = 'https://api.cognitive.microsofttranslator.com/';
  let location = 'eastus';

  const translateText = async (text) => {
    try {
      const response = await axios({
        baseURL: endpoint,
        url: '/translate',
        method: 'post',
        headers: {
          'Ocp-Apim-Subscription-Key': key,
          'Ocp-Apim-Subscription-Region': location,
          'Content-Type': 'application/json',
          'X-ClientTraceId': uuidv4().toString(),
        },
        params: {
          'api-version': '3.0',
          to: translatedLanguage,
        },
        data: [
          {
            text: text,
          },
        ],
      });

      // Check if the response data is in the expected format
      if (
        response.data &&
        response.data.length > 0 &&
        response.data[0].translations.length > 0
      ) {
        return response.data[0].translations[0].text;
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error(
        'Translation Error:',
        error.response ? error.response.data : error.message,
      );
      throw error; // Rethrow the error to allow handling in components
    }
  };

  return (
    <TranslationContext.Provider
      value={{ translatedLanguage, setTranslatedLangauge, translateText }}
    >
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslationContext = () => useContext(TranslationContext);
